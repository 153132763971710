var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "3", lg: "2" } },
            [
              _vm.articlesLoading
                ? _c("v-skeleton-loader", { attrs: { type: "list-item@10" } })
                : _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-list-item-group",
                        {
                          attrs: { mandatory: "", color: "accent" },
                          on: {
                            change: function($event) {
                              return _vm.changeArticle(
                                _vm.articles[_vm.selected].id
                              )
                            }
                          },
                          model: {
                            value: _vm.selected,
                            callback: function($$v) {
                              _vm.selected = $$v
                            },
                            expression: "selected"
                          }
                        },
                        _vm._l(_vm.articles, function(art) {
                          return _c(
                            "v-list-item",
                            { key: art.id },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", {
                                    domProps: {
                                      textContent: _vm._s(art.display)
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "9", lg: "10" } },
            [
              !_vm.articleHtml
                ? _c("v-skeleton-loader", { attrs: { type: "article@5" } })
                : _c("froala-view", {
                    model: {
                      value: _vm.articleHtml,
                      callback: function($$v) {
                        _vm.articleHtml = $$v
                      },
                      expression: "articleHtml"
                    }
                  })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }