import { render, staticRenderFns } from "./HelpArticle.vue?vue&type=template&id=7a57496b&scoped=true&"
import script from "./HelpArticle.vue?vue&type=script&lang=js&"
export * from "./HelpArticle.vue?vue&type=script&lang=js&"
import style0 from "./HelpArticle.vue?vue&type=style&index=0&id=7a57496b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a57496b",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCol,VList,VListItem,VListItemContent,VListItemGroup,VListItemTitle,VRow,VSkeletonLoader})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7a57496b')) {
      api.createRecord('7a57496b', component.options)
    } else {
      api.reload('7a57496b', component.options)
    }
    module.hot.accept("./HelpArticle.vue?vue&type=template&id=7a57496b&scoped=true&", function () {
      api.rerender('7a57496b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/components/help/HelpArticle.vue"
export default component.exports