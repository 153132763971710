<template>
  <div>
    <v-row>
      <v-col
        cols="3"
        lg="2"
      >
        <v-skeleton-loader
          v-if="articlesLoading"
          type="list-item@10"
        />
        <v-list
          v-else
          dense
        >
          <v-list-item-group
            v-model="selected"
            mandatory
            color="accent"
            @change="changeArticle(articles[selected].id)"
          >
            <v-list-item
              v-for="art in articles"
              :key="art.id"
            >
              <v-list-item-content>
                <v-list-item-title v-text="art.display" />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col
        cols="9"
        lg="10"
      >
        <v-skeleton-loader
          v-if="!articleHtml"
          type="article@5"
        />
        <froala-view
          v-else
          v-model="articleHtml"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import '@/plugins/vue-froala-wysiwyg'
import { azBlobService } from '@/shared/services'

export default {
  name: 'HelpArticle',

  props: {
    article: {
      type: String,
      default: 'Getting Started in the NOWW Platform'
    }
  },

  data () {
    return {
      selected: [],
      articles: [],
      articleHtml: '',
      articlesLoading: false
    }
  },

  watch: {
    '$route.params.article': function (article) {
      this.selectArticle(this.articleFolderToHtmlId(this.articles[this.selected].id))
    }
  },

  created () {
    this.getArticles()
    this.selectArticle(this.articleNameToId(this.article))
  },

  methods: {
    async getArticles () {
      this.articlesLoading = true
      var resp = await azBlobService.getHelpArticles()
      if (resp.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: resp.errorResponse,
          color: 'error'
        })
      } else {
        this.articles = resp.map(x => {
          return {
            ...x,
            display: x.name.replace(/.html/g, '')
          }
        })
        var folderId = this.articleFolderNameToId(this.article)
        this.selected = this.articles.findIndex(x => x.id === folderId)
      }
      this.articlesLoading = false
    },
    async selectArticle (htmlId) {
      this.articleHtml = ''
      var resp = await azBlobService.getHelpArticle(htmlId)
      if (resp.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: resp.errorResponse,
          color: 'error'
        })
      } else {
        this.articleHtml = resp
      }
    },
    changeArticle (folderId) {
      this.$router.push({ name: 'HelpArticle', params: { article: this.articleFolderIdToName(folderId) } })
    },
    articleFolderIdToName (folderId) {
      return folderId.replace(/.html/g, '')
    },
    articleFolderNameToId (folderName) {
      return `${folderName}.html/`
    },
    articleNameToId (name) {
      return `${name}.html/${name}.html`
    },
    articleFolderToHtmlId (folderId) {
      return `${folderId}${this.trimByChar(folderId, '/')}`
    },
    trimByChar (string, character) {
      const first = [...string].findIndex(char => char !== character)
      const last = [...string].reverse().findIndex(char => char !== character)
      return string.substring(first, string.length - last)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-list-item__title {
    white-space: normal;
}
</style>
